import React from "react"
import Img from "gatsby-image"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"

import CheckListPDF from "../../static/Checkliste_Homeoffice.pdf"

export const query = graphql`
    query {
        featureOne: file(relativePath: {eq: "feature-1.png"}) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        featureTwo: file(relativePath: {eq: "feature-2.png"}) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
    }
`

const PromisePage = ({ data }) => (
  <Layout>
    <SEO title="Unser Versprechen" />
    <div className="pageHero">
      <div className="container">
        <div className="row middle-sm">
          <div className="col-xs-12 center-xs col-sm-6 start-sm">
            <h1 className="pageHeroTitle">Leere Versprechungen oder ein offenes Hilfsangebot?</h1>
            <p className="pageHeroTruncate">Wir arbeiten seit vielen Jahren an der digitalen Transformation. Normalerweise entwickeln wir Web-Applikationen für komplexe E-Commerce- und ERP-Szenarien. Dafür nutzen wir konsequent den Systems Engineering Ansatz. In unseren Teams arbeiten Expertinnen und Experten aus unterschiedlichsten Ländern und in unterschiedlichsten Ländern. Unsere Kunden sprechen viele Sprachen und sind weltweit angesiedelt.</p>
            <p className="pageHeroTruncate">Und genau deswegen können wir Ihnen jetzt dieses Angebot machen. Wir arbeiten seit 14 Jahren mit digitalen Medien und meist webbasierten Applikationen, um unseren Arbeitsalltag und unsere Kundenkommunikation zu organisieren.</p>
          </div>
          <div className="col-xs-12 col-sm-6">
            <Img fluid={data.featureTwo.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </div>
    <div className="container section">
      <div className="row center-xs start-sm middle-sm">
        <div className="col-xs-12 col-sm-6">
          <Img fluid={data.featureOne.childImageSharp.fluid} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <h2 className="text-primary">Wir können Homeoffice!</h2>
          <p>Als Systems Engineers haben wir einen holistischen Blick auf die Dinge und betrachten immer einen Gesamtprozess, statt eines einzelnen Systems. Und wir denken alle digitalen Lösungen vom Menschen aus.</p>
          <p>Die aktuelle Coronakrise ist auch für uns eine Herausforderung. Aber eines haben wir bewiesen: wir konnten und wir können trotz vollständigem Homeoffice weiter arbeiten. Es hat nicht einmal einen halben Tag gedauert und wir hatten unsere Arbeitsplätze vollständig ins Homeoffice verlagert. Deswegen konnten wir sofort produktiv darüber nachdenken, was wir in der aktuellen Krise sinnvoll anbieten können.</p>
          <p>Wir sind davon überzeugt, dass unsere Erfahrungen mit digitaler Kollaboration entscheidende Faktoren sind, damit wir erfolgreich aus dieser Krise hervorgehen. Diese Erfahrungen wollen wir mit Ihnen teilen: einfach, schnell und zu fairen Konditionen.</p>
          <br/><br/>
          <a className="button secondary" href={CheckListPDF} download>Checkliste herunterladen</a>
        </div>
      </div>
    </div>
  </Layout>
)

export default PromisePage
